import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import { graphql } from 'gatsby'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/batteries-and-accessories/batteries/',
    imageId: 'batteriesImage',
    name: 'Batteries',
    alt: 'STIHL Batteries',
  },
  {
    link: '/stihl/batteries-and-accessories/chargers/',
    imageId: 'chargersImage',
    name: 'Chargers',
    alt: 'STIHL Chargers',
  },
]

const BatteriesAndAccessoriesPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Batteries and Accessories | Hutson Inc</title>
        <meta
          name='description'
          content='Eliminate the need for fuel with STIHL battery tools. Shop for STIHL batteries, chargers, and accessories at Hutson.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Batteries and Accessories',
                'item': 'https://www.hutsoninc.com/stihl/batteries-and-accessories/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Batteries and Accessories' />

      <Content>
        <Floater items={floaterItems} />

        <Promos data={promos} type='STIHL Batteries and Accessories' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query batteriesAndAccessoriesStihlQuery($category: String = "batteries-and-accessories") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(
      relativePath: { eq: "stihl/batteries-and-accessories/batteries-and-accessories-bg.jpg" }
    ) {
      ...FullWidthImage
    }
    batteriesImage: file(relativePath: { eq: "stihl/batteries-and-accessories/batteries.jpg" }) {
      ...FloatingGridImage
    }
    chargersImage: file(relativePath: { eq: "stihl/batteries-and-accessories/chargers.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default BatteriesAndAccessoriesPage
